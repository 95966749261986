import React, { useRef, useEffect, useContext, useCallback } from "react"
import { TimelineMax, TweenLite, Power3, gsap } from "gsap"
import { isMobileOnly } from "react-device-detect"
import ReactFullpage from "@fullpage/react-fullpage"
import { Controller, Scene } from "react-scrollmagic"
import { CSSPlugin } from "gsap/CSSPlugin"

import { GlobalDispatchContext, GlobalStateContext } from "./context"
import Social from "./social.js"

import { aboutBlurb } from "../constants/index"

const About = () => {
  const countLimit = 2
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  let _header = useRef(null)
  let _aboutBlurb = useRef(null)
  let _lowerSection = useRef(null)
  let _lowerLeft = useRef(null)
  let _lowerRight = useRef(null)
  let _aboutImage = useRef(null)
  let timeline = new TimelineMax({ paused: true })

  useEffect(() => {
    gsap.registerPlugin(CSSPlugin)
    if (isMobileOnly) {
      timeline
        .add(
          TweenLite.to(_header, 1, {
            css: { lineHeight: "4.5rem" },
            ease: Power3.easeOut,
            delay: state.landingAnimationCount < countLimit ? 5 : 0,
          })
        )
        .add(
          TweenLite.to(_aboutImage, 0.6, {
            css: { y: 0 },
            ease: Power3.easeIn,
            delay: 0,
          })
        )
        .add(
          TweenLite.to(_lowerSection, 1, {
            css: { backgroundColor: "#2b2b2b" },
            ease: Power3.easeInOut,
            delay: 0,
          })
        )
        .add(
          TweenLite.to(_aboutImage, 0.6, {
            css: {
              filter: "saturate(1)",
              backgroundSize: "120%",
              backgroundPositionX: "70%",
            },
            ease: Power3.easeIn,
            delay: 0,
          })
        )
        .play()
    } else {
      document.documentElement.style.setProperty(
        "--social-color",
        "rgb(27, 27, 27)"
      )
      timeline
        .add(
          TweenLite.to(_header, 1, {
            css: { lineHeight: "10rem" },
            ease: Power3.easeOut,
            delay: state.landingAnimationCount < countLimit ? 5 : 0,
          })
        )
        .add(
          TweenLite.to(_lowerSection, 1, {
            css: { backgroundColor: "#2b2b2b" },
            ease: Power3.easeInOut,
            delay: 0,
          })
        )
        .add("lower sections")
        .to(
          _lowerLeft,
          0.4,
          { css: { y: "0" }, ease: Power3.easeOut, delay: 0 },
          "lower sections"
        )
        .to(
          _lowerRight,
          0.4,
          { css: { y: "0" }, ease: Power3.easeOut, delay: 0.7 },
          "lower sections"
        )
        .add(
          TweenLite.to(_aboutBlurb, 0.7, {
            css: { opacity: 1, x: 0 },
            ease: Power3.easeInOut,
            delay: 0,
          })
        )
        .add(
          TweenLite.to(_aboutImage, 0.8, {
            css: {
              filter: "saturate(1)",
              backgroundSize: "120%",
              backgroundPositionX: "70%",
            },
            ease: Power3.easeIn,
            delay: 0,
          })
        )
        .play()
    }
  }, [timeline])

  const Fullpage = () => (
    <ReactFullpage
      licenseKey={"93C12D00-C8B440C7-9B7AFBDA-5C719E3A"}
      scrollingSpeed={700}
      autoScrolling={true}
      scrollBar={true}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <AboutContentMobile />
          </ReactFullpage.Wrapper>
        )
      }}
    />
  )

  const AboutContentMobile = () => {
    return (
      <div className="About-section-mobile Section-container">
        <div className="Section-header-section section">
          <div className="Section-header">
            <h2 className="About-header" ref={el => (_header = el)}>
              about.
            </h2>
          </div>
          <div className="About-image" ref={el => (_aboutImage = el)}></div>
        </div>
        <div className="fp-auto-height section">
          <div
            className="About-section-header-lower"
            ref={el => (_lowerSection = el)}
          >
            <Controller>
              <Scene
                duration={0}
                classToggle={[".Navbar-container-parent", "Scrolled"]}
                reverse={true}
                offset={
                  -Math.max(
                    (typeof document !== "undefined" &&
                      document.documentElement.clientHeight) / 2
                  )
                }
                indicators={false}
                triggerHook={0.25}
              >
                <p className="About-blurb" ref={el => (_aboutBlurb = el)}>
                  {aboutBlurb}
                </p>
              </Scene>
            </Controller>
          </div>
        </div>
        <div className="fp-auto-height section" id="Social-mobile-only">
          <Social />
        </div>
      </div>
    )
  }

  const AboutContent = () => {
    if (isMobileOnly) {
      return <Fullpage />
    } else {
      return (
        <div className="About-section Section-container">
          <div className="Section-header-section">
            <div className="Section-header">
              <h2 className="About-header" ref={el => (_header = el)}>
                about.
              </h2>
            </div>
            <div
              className="About-section-header-lower"
              ref={el => (_lowerSection = el)}
            >
              <div
                className="About-section-header-lower-left"
                ref={el => (_lowerLeft = el)}
              >
                <p className="About-blurb" ref={el => (_aboutBlurb = el)}>
                  {aboutBlurb}
                </p>
              </div>
              <div
                className="About-section-header-lower-right section"
                ref={el => (_lowerRight = el)}
              >
                <div
                  className="About-image"
                  ref={el => (_aboutImage = el)}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return <AboutContent />
}

export default About
