import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import About from "../components/about";


function AboutPage() {
    return (
        <Layout>
            <SEO title="about" />
            <About />
        </Layout>
    )

};

export default AboutPage;
